.App {
    padding: 50px;
}

.App-header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    color: white;
}

.App-header img {
    width: 150px;
    margin-right: 100px;
}

.App-header h1 {
    font-weight: 800;
}

.App p {
    color: white;
}

.App button.btn-yellow,
.App button.btn-red,
.modal-dialog button.btn-yellow,
.done-ctn a.btn-yellow {
    background-color: white;
    border-radius: 25px;
    padding: 5px 15px;
    font-weight: 800;
    font-size: 1.17em;
    display: flex;
    justify-content: center;
}

.App button.btn-yellow,
.modal-dialog button.btn-yellow,
.done-ctn a.btn-yellow {
    border: 3px solid #F8C907;
    min-width: 150px;
    color: black;
}

.crop-ctn button.btn-yellow {
    padding: 5px 10px;
    min-width: unset;
}

.App button.btn-yellow.filled,
.modal-dialog button.btn-yellow.filled,
.done-ctn a.btn-yellow.filled {
    background-color: #F8C907;
}

.App button.btn-red {
    border: 3px solid #ED1C24;
    padding: 5px 10px;
}

.App button.btn-yellow + button.btn-red {
    margin-left: 15px;
}

.App button.btn-yellow.disabled {
    border: 3px solid #B0B3B8;
    background-color: #B0B3B8 !important;
    color: #7C7E81;
}

.App button.btn-yellow input[type="file"] {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}

.App button.btn-yellow.add-certificate-btn::after {
    content: 'ADD CERTIFICATE';
}

.App button.btn-yellow.upload-btn::after {
    content: 'UPLOAD';
}

.App button.btn-yellow.upload-btn.loading::after {
    content: '';
}

.App > * + * {
    margin-top: 30px;
}

.uploads-ctn,
.done-ctn,
.location-ctn {
    background-color: #C3C6CB;
    border-radius: 10px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
}

.done-ctn,
.location-ctn {
    align-items: center;
}

.done-ctn > * {
    padding: 20px 50px;
    margin: unset;
    text-align: center;
}

.done-ctn > p {
    color: black;
}

.done-ctn > a {
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.done-ctn > a + a {
    margin-top: 20px;
}

.done-ctn > *:first-child {
    margin-top: 50px;
}

.done-ctn > *:last-child {
    margin-bottom: 70px;
}

.done-ctn .location-ctn {
    width: 100%;
}

.done-ctn .location-search-input,
.done-ctn .autocomplete-dropdown-container {
    width: 50%;
}

.upload-row,
.optional-upload-row {
    padding: 10px 50px;
    border-bottom: 1px solid #79797A;
}

.upload-row > *,
.optional-upload-row > * {
    margin: 5px 0;
}

.upload-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.optional-upload-row > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.optional-upload-row > div.side-2 {
    align-items: center;
}

.upload-row .left-column > * + *,
.optional-upload-row .left-column > * + * {
    margin-top: 0.5rem;
}

.upload-row .left-column > h3,
.optional-upload-row .left-column > h3 {
    font-weight: 800;
    font-size: calc(1.2rem + .4vw);
}

.upload-row .left-column > a,
.upload-row .left-column > a:hover,
.optional-upload-row .left-column > a,
.optional-upload-row .left-column > a:hover,
.optional-upload-row .left-column > button.side-2-btn,
.optional-upload-row .left-column > button.side-2-btn:hover {
    display: block;
    text-decoration: underline !important;
    cursor: pointer;
}

.optional-upload-row .left-column > button.side-2-btn {
    border: unset;
    padding: unset;
    background-color: unset;
}

.upload-row .left-column > a.ticket-download-link,
.upload-row .left-column > a.ticket-download-link:hover,
.optional-upload-row .left-column > a.ticket-download-link,
.optional-upload-row .left-column > a.ticket-download-link:hover {
    color: #0F5A1A;
}

.optional-upload-row > div.side-2 .left-column > h4 {
    margin-bottom: unset;
}

.optional-upload-row .right-column > button {
    display: inline-block;
}

.upload-row .ticket-link {
    color: black;
    margin-left: 5px;
}

.upload-row.submit {
    justify-content: center;
    padding-bottom: 30px;
    border-bottom: none;
}

.confirmation-modal ul {
    padding: unset;
    list-style-type: none;
}

.confirmation-modal ul li {
    font-weight: bold;
}

/* overwrite bootstrap dropdown CSS */
.optional-upload-row .upload-types > * {
    min-width: 360px;
}

.optional-upload-row .upload-types > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: black;
    background-color: #F6F6F6;
    border: none;
}

.optional-upload-row .upload-types > button.dropdown-toggle::after {
    border-top: 0.6em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}

.optional-upload-row .upload-types .dropdown-menu .dropdown-item {
    --bs-dropdown-link-active-bg: #B0B3B8;
}

.optional-upload-row .upload-types .dropdown-menu .dropdown-item.active {
    background-color: #B0B3B8;
}

/* overwrite bootstrap modal CSS */
.modal-open .modal {
    display: flex !important;
    flex-direction: column;
    height: 100%;
}

.modal-open .modal .modal-dialog {
    margin: auto;
}

.modal.fade {
    transition: none;
}

.modal-dialog {
    min-width: 70%;
}

.modal-dialog .modal-content {
    background-color: #EBEBEB;
    padding: 50px;
}

.cropping-modal .modal-dialog .modal-content {
    padding: 20px 20px;
}

.cropping-modal .modal-dialog .modal-content > * {
    padding: 0 10px;
}

.cropping-modal .modal-dialog .modal-content .header-ctn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.cropping-modal .modal-dialog .modal-content .header-ctn button.x-btn {
    border: none;
}

.modal-dialog .modal-content > * {
    margin-bottom: 15px;
}

.modal-dialog .modal-content h4 {
    font-weight: 800;
}

.modal-dialog .modal-content .crop-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
}

.modal-dialog .modal-content .footer-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

/* spinner */
.spinner {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.spinner:after {
    background: #111C2E;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* button spinner */
.spinner.loading {
    margin: 0;
    width: 3em;
    height: 3em;
    background: #F8C907;
    background: -moz-linear-gradient(left, #F8C907 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #F8C907 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #F8C907 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #F8C907 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #F8C907 10%, rgba(255, 255, 255, 0) 42%);
}

.spinner.loading:before {
    background: #F8C907;
}

.spinner.loading:after {
    background: white;
}

/* smaller screens */
@media all and (max-width: 992px) {
    .App p,
    .App-header {
        justify-content: center;
        text-align: center;
    }

    .App-header > img {
        margin: 0 0 20px 0;
    }
}

@media all and (max-width: 768px) {
    .App {
        padding: 15px;
    }

    .uploads-ctn,
    .done-ctn {
        min-width: 350px;
    }

    .done-ctn > * {
        padding: 10px 30px;
        margin: unset;
        text-align: center;
    }

    .App button.btn-yellow,
    .modal-dialog button.btn-yellow,
    .done-ctn a.btn-yellow {
        padding: 5px 12px;
    }

    .App button.btn-yellow,
    .modal-dialog button.btn-yellow,
    .done-ctn a.btn-yellow {
        min-width: unset;
    }

    .App button.btn-yellow + button.btn-red {
        margin-left: 5px;
    }

    .App button.btn-yellow.add-certificate-btn {
        padding: 5px 10px;
    }

    .App button.btn-yellow.add-certificate-btn::after {
        content: '＋';
    }

    .App button.btn-yellow.upload-btn::after {
        content: '↑';
    }

    .App button.btn-yellow.upload-btn.loading::after {
        content: '';
    }

    .upload-row,
    .optional-upload-row {
        padding: 10px 20px;
    }

    .upload-row > *:last-child,
    .optional-upload-row .right-column {
        margin-left: 5px;
    }

    /* overwrite bootstrap dropdown CSS */
    .optional-upload-row .upload-types > * {
        min-width: unset;
    }

    .modal-dialog .modal-content {
        padding: 25px;
    }

    .modal-dialog .modal-content .footer-ctn button.btn-yellow + button.btn-yellow {
        margin-left: 5px;
    }

    @media all and (max-width: 500px) {
        .done-ctn .location-search-input,
        .done-ctn .autocomplete-dropdown-container {
            width: 100%;
        }

        /* fix option-upload-row with overly long upload-types */
        .optional-upload-row .left-column > *,
        .optional-upload-row .left-column .upload-types > button {
            max-width: 200px;
            white-space: normal;
        }
    }
}
